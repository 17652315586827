@import '../../assets/styles/variables.sass'


.ui-search-select
  outline: none
  .ui-search-select
    &__control
      display: flex
      border: 1px solid rgba(0, 0, 0, 0.5)
      border-radius: 4px
      overflow: hidden
      height: 44px
      &:hover
        border-color: rgba(0, 0, 0, 0.5)
      &--is-focused
        outline: none!important
        box-shadow: none
    &__single-value
      font: 1.9rem $r
    &__input-container
      font: 1.9rem $r
      border: none
    &__placeholder
      font: 1.3rem/2rem $r
      color: rgba(0, 0, 0, .6)
      padding: 3px 0
    &__option
      font: 1rem $r
      &:hover
        background: rgba(0, 0, 0, 0.04)
      &--is-selected
        background: rgba(25, 118, 210, 0.08)
        color: $black
      &--is-focused
        background: rgba(25, 118, 210, 0.08)
        color: $black
